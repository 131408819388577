import { useStaticQuery, graphql } from 'gatsby';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

export default () => {
  const language = useLanguage();
  const { projects } = useStaticQuery(graphql`
    query useProjects {
      projects: allWordpressWpProjects {
        edges {
          node {
            id
            wpml_current_locale
            title
            content
            excerpt
            link
          }
        }
      }
    }
  `);

  return projects.edges?.filter(({ node }) => node.wpml_current_locale === language);
};
