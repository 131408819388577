import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';

import Layout from '../../layouts';
import useProjects from '../../components/use-projects';
import Container from '../../components/container';
import Content from '../../components/content';

// remember Project uses this component, but not this query
export const pageQuery = graphql`
  query ProjectsPage($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }
      featured_media {
        localFile {
          name
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 350, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      content
    }
  }
`;

export const ProjectsTemplate = ({ data }) => {
  const { page } = data;
  const projects = useProjects();
  const i18n = useI18n();

  return (
    <Layout page={page}>
      <Container>
        <StyledBackgroundImage fluid={page?.featured_media?.localFile?.childImageSharp?.fluid} />
      </Container>
      <Projects>
        <Container small>
          {projects.map(({ node }) => (
            <Project key={node.id}>
              <Content>
                <h2>{node.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                <p>
                  <Link to={node.link}>{i18n?.moreProjectInfo}</Link>
                </p>
              </Content>
            </Project>
          ))}
        </Container>
      </Projects>
    </Layout>
  );
};

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 12.78rem;
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-left: 0;
    margin-right: 0;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    height: 19.44rem;
  }
`;


const Projects = styled.div`
  padding: ${(props) => props.theme.sectionSpace} 0;
`;

const Project = styled.section`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.primaryColor};
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.grid.columnGap};
  }
`;
export default ProjectsTemplate;
